export interface Activity {
    name: string
    title: string
    md?: string | (() => Promise<string | typeof import("*.md")>) // 通常是通过异步require引入一个markdown文件。（利用了markdown-loader包，实际上require markdown文件得到的就是一段html字符串，因此理论上，这里填写markdown字符串也是没有任何问题的）
    href?: string
    abstract?: string
    img?: string
    tag?: { content: string, color: string }
}

function state() {
    const activities: Activity[] = [
        {
            name: "Special Issue of Pattern Recognition: Graph Foundation Model for Medical Image Analysis",
            title: "Pattern Recognition Special Issue",
            md: () => import(/* webpackChunkName: "shrec22" */ '../data/activity/PRSI.md'),
            img: require("../assets/research/PRSI.png"),
        },

        {
            name: "SHREC 2022 Track: Open-Set 3D Object Retrieval",
            title: "The objective of this track is to evaluate the performance of the different 3D shape retrieval algorithms under the Open-Set setting, which is an unknown-category shape retrieval task.",
            md: () => import(/* webpackChunkName: "shrec22" */ '../data/activity/shrec22.md'),
            img: require("../assets/activity/shrec22.png"),
        },
    ]
    const activities_zh: Activity[] = [
        {
            name: "模式识别特刊征稿：图基础模型在医学图像分析中的应用",
            title: "Pattern Recognition Special Issue",
            md: () => import(/* webpackChunkName: "shrec22" */ '../data/activity/PRSI.md'),
            img: require("../assets/research/PRSI.png"),
        },
        {
            name: "SHREC 2022 赛道：开集3D物体检索",
            title: "本赛道的目的是评估不同3D形状检索算法在开放集设置下的性能。",
            md: () => import(/* webpackChunkName: "shrec22" */ '../data/activity/shrec22.md'),
            img: require("../assets/activity/shrec22.png"),
        },
    ]
    return { zh: activities_zh, en: activities }
}

export default state()
