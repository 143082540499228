
import Vue from "vue";
import activities, { Activity as ActivityType } from "@/store/activity";
import { Component } from "vue-property-decorator";

@Component
export default class Activity extends Vue {
  name = "Activity";

  get contents(): ActivityType[] {
    return this.$i18n.locale === "zh" ? activities.zh : activities.en;
  }

  get cardHeight(): number {
    if (this.$vuetify.breakpoint.xl) return 450;
    else return 350;
  }
}
