
import Vue from "vue";
import { Component } from "vue-property-decorator";
import S404 from "@/views/404.vue";
import Markdown from "@/components/Markdown.vue";
import activities, { Activity } from "@/store/activity";

@Component({
  components: { Markdown, S404 },
})
export default class ActivityDetail extends Vue {
  name = "ActivityDetail";

  get activity(): Activity | undefined {
    const a = this.$i18n.locale === "zh" ? activities.zh : activities.en;
    return a.find((v) => v.name === this.$route.params.name);
  }
}
